<template>
  <div class="tab">
    <Chart />

    <List />
  </div>
</template>

<script>
import Chart from '@/modules/network-and-rank/rank/chart/Index.vue'
import List from '@/modules/network-and-rank/rank/List.vue'

export default {
  components: {
    Chart,
    List
  },
  data () {
    return {
      mountFetch: false,
      preloader: true,
      dataRank: {},
      dataRanks: []
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/private/widgets/network-rank')
        if (resp.status === 200) {
          this.dataRank = resp.data.data
        }

        const ranks = await this.$axios.get(
          '/private/ranks')
        if (ranks.status === 200) {
          this.dataRanks = ranks.data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (max-width: 768px) {
    padding: 0 17px;
  }
}
</style>
