<template>
  <div class="list">
    <h3>
      {{ $t('views.default.network.rank.list.ranks') }}
    </h3>

    <div
      v-if="_width > 1280"
      class="list__content"
    >
      <div
        v-for="item in data"
        :key="item.id"
        class="list__content__item"
      >
        <div class="list__content__item-container">
          <div class="list__content__item-container__label">
            {{ $t('views.default.network.rank.list.container') }}
          </div>

          <div
            class="list__content__item-container__info"
            :style="{
              background: item.color
            }"
          >
            <div class="list__content__item-container__info-percent">
              {{ item.percent }}%
            </div>

            {{ $t('views.default.network.rank.list.container') }}
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th>
                {{ $t('views.default.network.rank.list.rank') }}
              </th>

              <th>
                {{ $t('views.default.network.rank.list.wholesale-turnover') }}
              </th>

              <th>
                {{ $t('views.default.network.rank.list.retail-turnover') }}
              </th>

              <th>
                {{ $t('views.default.network.rank.list.bonus-in-bulk') }}
              </th>

              <th>
                {{ $t('views.default.network.rank.list.retail-bonus') }}
              </th>

              <th>
                {{ $t('views.default.network.rank.list.rank-bonus') }}
              </th>
              <th>
                {{ $t('views.default.network.rank.list.max-level') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="rank in item.ranks"
              :key="rank.id"
              :class="{
                radius: item.ranks.length === 1
              }"
            >
              <td>
                <div class="table-container bold">
                  {{ rank.name }}
                </div>
              </td>

              <td>
                <div class="table-container">
                  {{ $t('views.default.network.rank.list.from') }} ${{ $format.wallet(rank.amount) }}
                </div>
              </td>

              <td>
                <div class="table-container">
                  {{ $t('views.default.network.rank.list.from') }} ${{ $format.wallet(rank.amount_products) }}
                </div>
              </td>

              <td>
                <div class="table-container bold">
                  {{ rank.percent }}%
                </div>
              </td>

              <td>
                <div class="table-container bold">
                  {{ rank.percent_products }}%
                </div>
              </td>

              <td>
                <div class="table-container bold">
                  +${{ $format.wallet(rank.bonus) }}
                </div>
              </td>
              <td>
                <div class="table-container bold">
                  {{ rank.max_level }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      v-else
      class="list__content"
    >
      <div
        v-for="item in data"
        :key="item.id"
        class="list__content__mobile-item"
      >
        <div
          class="list__content__mobile-item__info"
          :style="{
            background: item.color
          }"
        >
          <div class="list__content__mobile-item__info__start">
            <div class="list__content__mobile-item__info__start-name">
              {{ item.percent }}%
            </div>

            {{ $t('views.default.network.rank.list.container') }}
          </div>

          <div
            class="list__content__mobile-item__info__end"
            :class="{
              active: activeRankInfo === item.id
            }"
            @click="
              activeRankInfo !== item.id
                ? activeRankInfo = item.id : activeRankInfo = null
            "
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 9L12 15L6 9" stroke="white" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </div>
        </div>

        <template
          v-if="activeRankInfo === item.id"
        >
          <div
            v-for="rank in item.ranks"
            :key="rank.id"
            class="list__content__mobile-item__rank"
          >
            <div class="list__content__mobile-item__rank-name">
              {{ rank.name }}
            </div>

            <div class="list__content__mobile-item__rank__line">
              {{ $t('views.default.network.rank.list.wholesale-turnover') }}

              <span>
                {{ $t('views.default.network.rank.list.from') }} ${{ $format.wallet(rank.amount) }}
              </span>
            </div>

            <div class="list__content__mobile-item__rank__line">
              {{ $t('views.default.network.rank.list.retail-turnover') }}

              <span>
                {{ $t('views.default.network.rank.list.from') }} ${{ $format.wallet(rank.amount_products) }}
              </span>
            </div>

            <div class="list__content__mobile-item__rank__line">
              {{ $t('views.default.network.rank.list.bonus-in-bulk') }}

              <span>
                {{ rank.percent }}%
              </span>
            </div>

            <div class="list__content__mobile-item__rank__line">
              {{ $t('views.default.network.rank.list.retail-bonus') }}

              <span>
                {{ rank.percent_products }}%
              </span>
            </div>

            <div class="list__content__mobile-item__rank__line">
              {{ $t('views.default.network.rank.list.rank-bonus') }}

              <span>
                +${{ $format.wallet(rank.bonus) }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- <div class="list__loading">
      <LightButton
        :text="'Загрузить PDF'"
      />
    </div> -->
  </div>
</template>

<script>
// import LightButton from '@/ui/buttons/LightButton.vue'

export default {
  components: {
    // LightButton
  },
  data () {
    return {
      activeRankInfo: null,
      data: null
    }
  },
  computed: {
    _width () {
      return this.$store.state.window.width
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const response = await this.$axios.get('private/ranks')

        if (response.status === 200) {
          this.data = response?.data?.data
        }
      } catch (err) {
        throw new Error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  width: 100%;

  h3 {
    font-size: 26px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
  }

  &__content {
    @media screen and (max-width: 1280px) {
      margin-top: 15px;
    }

    &__item {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 100px auto;
      gap: 10px;

      &-container {
        &__label {
          margin-bottom: 18px;
          font-size: 12px;
          line-height: 100%;
          color: #787C8D;
          font-family: Medium;
        }

        &__info {
          height: calc(100% - 30px);
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-radius: 10px;
          text-align: center;
          font-size: 12px;
          line-height: 100%;
          color: #FFFFFF;
          font-family: Medium;

          &-percent {
            font-size: 26px;
            line-height: 100%;
            color: #FFFFFF;
            font-family: SemiBold;
            text-transform: lowercase;
          }
        }
      }

      table {
        border-spacing: 0;

        thead {
          tr {
            th {
              padding: 0 0 18px 40px;
              font-size: 12px;
              line-height: 100%;
              color: #787C8D;
              font-family: Medium;
              text-align: left;

              @media screen and (max-width: 1440px) {
                padding: 0 0 18px 30px;
              }
            }
          }
        }

        tbody {
          tr {
            &.radius {
              td {
                &:first-child {
                  border-radius: 10px 0 0 10px !important;
                }

                &:last-child {
                  border-radius: 0 10px 10px 0 !important;
                }
              }
            }

            &:first-child {
              td {
                &:first-child {
                  border-radius: 10px 0 0 0;
                }

                &:last-child {
                  border-radius: 0 10px 0 0;
                }
              }
            }

            &:last-child {
              td {
                &:first-child {
                  border-radius: 0 0 0 10px;
                }

                &:last-child {
                  border-radius: 0 0 10px 0;
                }

                .table-container {
                  border: none;
                }
              }
            }

            td {
              padding: 0;
              background: #FFFFFF;

              &:first-child {
                padding: 0 0 0 10px;
              }

              &:last-child {
                padding: 0 10px 0 0;
              }

              .table-container {
                border-bottom: 1px solid rgba(156, 163, 184, .6);
                padding: 22px 0 22px 40px;
                font-size: 14px;
                line-height: 100%;
                color: #202539;
                font-family: Medium;

                @media screen and (max-width: 1440px) {
                  padding: 22px 0 22px 20px;
                }

                &.bold {
                  font-family: SemiBold;
                }
              }
            }
          }
        }
      }
    }

    &__mobile-item {
      &__info {
        height: 60px;
        margin-bottom: 5px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;

        &__start {
          font-size: 12px;
          line-height: 100%;
          color: #FFFFFF;
          font-family: Medium;
          text-transform: lowercase;

          &-name {
            font-size: 26px;
            line-height: 100%;
            color: #FFFFFF;
            font-family: SemiBold;
          }
        }

        &__end {
          height: 24px;
          background: rgba(255, 255, 255, .4);
          border-radius: 2px;
          cursor: pointer;

          &.active {
            transform: rotate(180deg);
          }
        }
      }

      &__rank {
        margin-bottom: 5px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        background: #FFFFFF;
        box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
        border-radius: 10px;

        &-name {
          font-size: 16px;
          line-height: 100%;
          color: #202539;
          font-family: SemiBold;
        }

        &__line {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 12px;
          line-height: 100%;
          color: #787C8D;
          font-family: Medium;

          span {
            font-size: 14px;
            line-height: inherit;
            text-align: right;
            color: #202539;
            font-family: inherit;
          }
        }
      }
    }
  }

  &__loading {
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;

    button {
      max-width: 250px;
    }
  }
}
</style>
