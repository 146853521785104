<template>
  <div class="stats">
    <div class="stats__start">
      <div
        class="stats__start-now"
        :style="{
          background: value.rank?.group?.color
        }"
      >
        {{ value.rank?.name }}
      </div>

      <ArrowButton
        v-if="isMainPage"
        :text="$t('views.default.network.rank.chart.stats.about-ranks')"
        @click="$router.push({ name: 'Rank' })"
      />
    </div>

    <div class="stats-next">
      {{ $t('views.default.network.rank.chart.stats.next', { value: value.nextRank?.name }) }}
    </div>

    <div class="stats__graphs">
      <div class="stats__graphs__item">
        <div class="stats__graphs__item-name blue-color">
          {{ $t('views.default.network.rank.chart.stats.procurement') }}
        </div>

        <div class="stats__graphs__item-value">
          ${{ $format.wallet(value.left) }}
        </div>

        <div class="stats__graphs__item-percent">
          <div class="stats__graphs__item-percent__info">
            <div class="stats__graphs__item-percent__info__value">
              ${{ $format.wallet(value.turnover) }}/${{ $format.wallet(value.nextRank?.amount) }}
            </div>
          </div>

          <div
            class="stats__graphs__item-percent__progress blue-color"
            :style="{
              width: `${value.filled}%`
            }"
          >
            <div
              class="stats__graphs__item-percent__progress-percent"
              :class="{
                'to-left': value.filled <= 10,
                'to-right': value.filled >= 90
              }"
            >
              {{ value.filled }}%
            </div>
          </div>
        </div>
      </div>

      <div class="stats__graphs__item">
        <div class="stats__graphs__item-name green-color">
          {{ $t('views.default.network.rank.chart.stats.turnover') }}
        </div>

        <div class="stats__graphs__item-value">
          ${{ $format.wallet(value.left_products) }}
        </div>

        <div class="stats__graphs__item-percent">
          <div class="stats__graphs__item-percent__info">
            <div class="stats__graphs__item-percent__info__value">
              ${{ $format.wallet(value.turnover_products) }}/${{ $format.wallet(value.nextRank?.amount_products) }}
            </div>
          </div>

          <div
            class="stats__graphs__item-percent__progress green-color"
            :style="{
              width: `${value.filled_products}%`
            }"
          >
            <div
              class="stats__graphs__item-percent__progress-percent"
              :class="{
                'to-left': value.filled_products <= 10,
                'to-right': value.filled_products >= 90
              }"
            >
              {{ value.filled_products }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/ui/buttons/ArrowButton.vue'

export default {
  components: {
    ArrowButton
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    isMainPage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.stats {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__start {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-now {
      margin: 0 auto 0 0;
      padding: 3px 8px;
      border-radius: 20px;
      color: #FFFFFF;
      font-family: Medium;
      font-size: 12px;
      line-height: 100%;
    }
  }

  &-next {
    font-size: 18px;
    line-height: 100%;
    color: #202539;
    font-family: SemiBold;
  }

  &__graphs {
    margin: auto 0 15px 0;
    display: flex;
    gap: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 30px;
    }

    &__item {
      width: 100%;

      &-name {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 100%;
        font-family: Medium;

        &.blue-color {
          color: #0031FF;
        }

        &.green-color {
          color: #00B207;
        }
      }

      &-value {
        font-size: 24px;
        line-height: 100%;
        color: #202539;
        font-family: SemiBold;
      }

      &-percent {
        position: relative;
        height: 4px;
        width: 100%;
        margin-top: 30px;
        background: #CAD2EB;
        border-radius: 10px;

        &__info {
          position: absolute;
          bottom: 100%;
          left: 0;
          width: 100%;
          margin-bottom: 5px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          font-size: 11px;
          line-height: 100%;
          color: #787C8D;

          &__value {
            font-size: 12px;
            line-height: inherit;
            color: inherit;
            font-family: SemiBold;
          }
        }

        &__progress {
          position: relative;
          height: 100%;
          max-width: 100%;
          margin-top: auto;
          border-radius: 10px;

          &.blue-color {
            background: #2F57E9;
          }

          &.green-color {
            background: #00B307;
          }

          &-percent {
            position: absolute;
            top: 100%;
            right: -8px;
            margin: 5px 0 0;
            font-size: 10px;
            line-height: 100%;
            text-align: center;
            color: #787C8D;

            &.to-left {
              left: 0;
              right: auto;
            }

            &.to-right {
              right: 0;
            }
          }
        }
      }
    }
  }
}
</style>
