<template>
  <div
    class="rank"
    :style="{
      background: value.rank?.group?.color
    }"
  >
    <div class="rank__start">
      {{ $t('views.default.network.rank.chart.rank.your-rank') }}

      <div class="rank__start-number">
        {{ value.rank?.name }}
      </div>
    </div>

    <div class="rank__end">
      <div class="rank__end__bonus">
        <div class="rank__end__bonus__item">
          <div class="rank__end__bonus__item-name">
            {{ $t('views.default.network.rank.chart.rank.wholesale-bonus') }}
          </div>

          <div class="rank__end__bonus__item-content">
            {{ value.rank?.percent }}%
          </div>
        </div>

        <div class="rank__end__bonus__item">
          <div class="rank__end__bonus__item-name">
            {{ $t('views.default.network.rank.chart.rank.retail-bonus') }}
          </div>

          <div class="rank__end__bonus__item-content">
            {{ value.rank?.percent_products }}%
          </div>
        </div>

        <div class="rank__end__bonus__item">
          <div class="rank__end__bonus__item-name">
            {{ $t('views.default.network.rank.chart.rank.rank-bonus') }}
          </div>

          <div class="rank__end__bonus__item-content">
            +${{ $format.wallet(value.rank?.bonus) }}
          </div>
        </div>
      </div>

      <div class="rank__end__turnover">
        <div class="rank__end__turnover__item">
          <div class="rank__end__turnover__item-name">
            {{ $t('views.default.network.rank.chart.rank.wholesale-turnover') }}
          </div>

          <div class="rank__end__turnover__item-from">
            {{ $t('views.default.network.rank.chart.rank.from') }} ${{ $format.wallet(value.rank?.amount) }}
          </div>
        </div>

        <div class="rank__end__turnover__item">
          <div class="rank__end__turnover__item-name">
            {{ $t('views.default.network.rank.chart.rank.retail-turnover') }}
          </div>

          <div class="rank__end__turnover__item-from">
            {{ $t('views.default.network.rank.chart.rank.from') }} ${{ $format.wallet(value.rank?.amount_products) }}
          </div>
        </div>

        <div class="rank__end__turnover__item">
          <div class="rank__end__turnover__item-name">
            {{ $t('views.default.network.rank.chart.rank.max-level') }}
          </div>

          <div class="rank__end__turnover__item-from">
            {{ $format.wallet(value.rank?.max_level) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.rank {
  width: 100%;
  padding: 20px;
  border-radius: 10px;

  &__start {
    margin-bottom: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 18px;
    line-height: 100%;
    color: #FFFFFF;
    font-family: SemiBold;

    &-number {
      font-size: 60px;
      line-height: 100%;
      text-align: right;
      color: rgba(255, 255, 255, .3);
      font-family: SemiBold;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  &__end {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 30px;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__bonus {
      display: flex;
      gap: 20px;

      &__item {
        width: 110px;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        &-name {
          margin-bottom: 15px;
          font-size: 12px;
          line-height: 100%;
          color: #fff;
          font-family: Medium;
        }

        &-content {
          font-size: 24px;
          line-height: 100%;
          color: #FFFFFF;
          font-family: SemiBold;
        }
      }
    }

    &__turnover {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;

      &__item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 15px;

        &-name {
          font-size: 12px;
          line-height: 100%;
          color: rgba(255, 255, 255, .6);
          font-family: Medium;
          text-align: left;
        }

        &-from {
          font-size: 14px;
          line-height: 100%;
          text-align: right;
          color: #FFFFFF;
          font-family: Medium;
          text-align: right;
        }
      }
    }
  }
}
</style>
