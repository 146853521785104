<template>
  <Container
    class="chart"
    :preloader="preloader"
    style="overflow: hidden !important;"
  >
    <template #content>
      <Stats
        :value="data"
      />

      <Rank
        :value="data"
      />
    </template>
  </Container>
</template>

<script>
import Container from '@/ui/preloader/ItemPreloaderContainer.vue'
import Stats from '@/modules/network-and-rank/rank/chart/Stats.vue'
import Rank from '@/modules/network-and-rank/rank/chart/Rank.vue'

export default {
  components: {
    Container,
    Stats,
    Rank
  },
  data () {
    return {
      preloader: true,
      data: {}
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const { status, data } = await this.$axios.get('private/widgets/network-rank')

        if (status === 200) {
          this.data = data?.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 10px;
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
  border-radius: 15px;

  @media screen and (max-width: 1440px) {
    flex-direction: column;
  }
}
</style>
